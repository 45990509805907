define('oscarparamo/mixins/google-pageview', ['exports', 'ember', 'oscarparamo/config/environment'], function (exports, _ember, _oscarparamoConfigEnvironment) {
  exports['default'] = _ember['default'].Mixin.create({
    pageviewToGA: (function (page, title) {
      page = page ? page : this.get('url');
      title = title ? title : this.get('url');

      if (_ember['default'].get(_oscarparamoConfigEnvironment['default'], 'googleAnalytics.webPropertyId') != null) {
        var trackerType = _ember['default'].getWithDefault(_oscarparamoConfigEnvironment['default'], 'googleAnalytics.tracker', 'analytics.js');

        if (trackerType === 'analytics.js') {
          var globalVariable = _ember['default'].getWithDefault(_oscarparamoConfigEnvironment['default'], 'googleAnalytics.globalVariable', 'ga');

          window[globalVariable]('send', 'pageview', {
            page: page,
            title: title
          });
        } else if (trackerType === 'ga.js') {
          window._gaq.push(['_trackPageview']);
        }
      }
    }).on('didTransition')
  });
});