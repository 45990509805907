define('oscarparamo/router', ['exports', 'ember', 'oscarparamo/config/environment', 'oscarparamo/mixins/google-pageview'], function (exports, _ember, _oscarparamoConfigEnvironment, _oscarparamoMixinsGooglePageview) {

  var Router = _ember['default'].Router.extend(_oscarparamoMixinsGooglePageview['default'], {
    location: _oscarparamoConfigEnvironment['default'].locationType,
    rootURL: _oscarparamoConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('about');
  });

  exports['default'] = Router;
});